// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Customform from "../../blocks/customform/src/Customform";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ShareCalendar from "../../blocks/ShareCalendar/src/ShareCalendar";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Documentation from "../../blocks/Documentation/src/Documentation";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PledgeTracking from "../../blocks/PledgeTracking/src/PledgeTracking";
import CfEmiCalculation from "../../blocks/CfEmiCalculation/src/CfEmiCalculation";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Payments from "../../blocks/Payments/src/Payments";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Videos from "../../blocks/videos/src/Videos";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Playlist4 from "../../blocks/Playlist4/src/Playlist4";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";



const routeMap = {
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Customform:{
 component:Customform,
path:"/Customform"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ShareCalendar:{
 component:ShareCalendar,
path:"/ShareCalendar"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PledgeTracking:{
 component:PledgeTracking,
path:"/PledgeTracking"},
CfEmiCalculation:{
 component:CfEmiCalculation,
path:"/CfEmiCalculation"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Payments:{
 component:Payments,
path:"/Payments"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Videos:{
 component:Videos,
path:"/Videos"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Playlist4:{
 component:Playlist4,
path:"/Playlist4"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},

  Home: {
component:Pushnotifications,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
